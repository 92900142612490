<template>
  <div>
    <div class="text-gray-800 bg-white mt-8 md:mt-[7.5%]">
      <div class="mx-auto text-center">
        <h1 class="mb-4 text-3xl font-bold md:text-4xl">{{ header }}</h1>
        <p class="max-w-2xl mx-4 mb-8 text-base md:mx-auto md:text-lg">{{ mission }}</p>
        <div class="flex flex-col items-center md:flex-row md:justify-center md:space-x-12">
          <div v-for="person in team" :key="person.id" class="mb-8 text-center md:mb-0">
            <img
              class="w-40 h-40 mx-auto mb-4 rounded-full md:w-48 md:h-48"
              :src="person.imageUrl"
              :alt="`Portrait of ${person.name}`"
              loading="lazy"
            />
            <h1 class="text-lg font-bold md:text-xl">
              {{ person.name }}<br />({{ person.initials }})
            </h1>
            <p class="text-lg font-bold text-gray-600 md:text-xl">{{ person.role }}</p>
            <div class="flex justify-center my-2 space-x-4">
              <a :href="person.linkedin" class="text-blue-600">
                <img class="w-6 h-6" src="/icons/linkedin_icon.png" loading="lazy" />
              </a>
              <a :href="person.email" class="text-blue-600">
                <img class="w-6 h-6" src="/icons/email_icon.png" loading="lazy" />
              </a>
            </div>
            <p class="italic text-gray-600">Previously:</p>
            <p class="text-gray-800">{{ person.previousTitle }}</p>
            <p class="font-bold text-gray-800">{{ person.previousRole }}</p>
            <p class="text-gray-800">@ {{ person.previousCompany }}</p>
          </div>
        </div>
        <div class="relative mt-8 md:mt-[10%]">
          <!-- Image container -->
          <div class="bg-white">
            <img class="w-full" src="/images/copenhagen.png" alt="Copenhagen" loading="lazy" />
            <div class="h-16 md:h-[15vh] bg-black"></div>
          </div>
          <!-- Text overlay -->
          <div
            class="absolute inset-x-0 bottom-0 text-white transform translate-y-[-40%] md:translate-y-[-70%]"
          >
            <h2 class="text-4xl md:text-8xl">Copenhagen</h2>
            <p class="text-xl md:text-3xl">Denmark</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const header = 'The Team'
const mission =
  'AlterEcho was built to make a difference where it matters most. We empower operators to work smarter, with less stress, while ensuring that patients receive medicines crafted with the pinnacle of today’s technology. Our mission is to simplify, optimize, and elevate the standards of aseptic manufacturing.'

const team = ref([
  {
    id: 1,
    imageUrl: '/images/MM.png',
    name: 'Mr. Data Clickolini',
    initials: 'M.M.',
    role: 'CEO',
    linkedin: '#',
    email: 'MM@AlterEcho.IO',
    previousTitle: 'Senior “Let’s Optimize This” Strategist',
    previousRole: 'Autonomous Systems Engineer',
    previousCompany: 'Somewhere Between Draft 5 and The ‘Final’ Final'
  },
  {
    id: 2,
    imageUrl: '/images/SMV.png',
    name: 'Mr. Algorithm McByte',
    initials: 'S.M.V.',
    role: 'CTO',
    linkedin: '#',
    email: 'SMV@AlterEcho.IO',
    previousTitle: 'Lead “Chief of Things That Go Beep”',
    previousRole: 'Robotics & Automation Engineer',
    previousCompany: 'Definitely Not a Secret Robot Factory'
  }
])
</script>

<style scoped>
#map {
  height: 100%;
  width: 100%;
}
</style>
