<template>
  <div class="flex flex-col w-full h-full">
    <!-- First Section: Black Background -->
    <div class="flex flex-col w-full lg:flex-row">
      <!-- Main content area -->
      <div
        class="order-2 lg:order-1 w-full lg:w-[75%] overflow-hidden text-white bg-black rounded-tr-3xl rounded-br-3xl shadow-xl h-full text-justify justify-center"
      >
        <div class="grid justify-center h-full grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <div
            class="flex flex-col items-center"
            v-for="(procedure, index) in procedures"
            :key="index"
          >
            <p class="w-full p-4 lg:p-8 h-[75%] item-start text-sm lg:text-base">
              {{ procedure.description }}
            </p>
            <img
              class="w-[60%] lg:w-[80%] p-4 item-end"
              :src="procedure.image"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </div>

      <!-- Title section -->
      <div class="order-1 lg:order-2 w-full lg:w-[30%] px-4 py-6 lg:pt-[2%]">
        <h1 class="px-2 text-3xl lg:text-5xl lg:px-5">
          <b>CURRENT</b><br /><b>S</b>TANDARD<br /><b>O</b>PERATING<br /><b>P</b>ROCEDURES
        </h1>
        <br />
        <h1 class="text-sm lg:text-base ml-2 lg:ml-5 text-justify lg:mr-[30%] justify-normal">
          This is just the tip of the iceberg of how well-intended processes in aseptic
          manufacturing have become tangled in inefficiency.
        </h1>
      </div>
    </div>

    <!-- Second Section: Purple Background -->
    <div
      class="relative lg:absolute lg:left-0 flex flex-col lg:flex-row w-full lg:transform lg:-translate-y-[40%] lg:top-[60%] mt-8 lg:mt-0"
    >
      <div class="hidden lg:flex lg:flex-col lg:justify-end lg:w-[50%] lg:px-5 lg:py-[4%]">
        <!-- Optional Content remains unchanged -->
      </div>

      <div
        class="w-full lg:w-[52.5%] h-full text-white bg-[#6f00ff] shadow-xl rounded-tl-3xl rounded-bl-3xl"
      >
        <div class="flex flex-col h-full lg:flex-row">
          <!-- Left Column -->
          <div
            class="w-full lg:w-[45%] flex flex-col pt-6 lg:pt-12 justify-between overflow-hidden rounded-bl-3xl"
          >
            <h1 class="mb-4 text-2xl text-center text-black lg:text-3xl lg:text-right">
              Our Solution:<br />
            </h1>
            <h1 class="text-4xl font-bold text-center lg:text-5xl lg:text-right lg:pl-8">
              AlterEcho
            </h1>
            <!-- First Image (Desktop Only) -->
            <img
              class="hidden w-full lg:block"
              :src="solutions[0].image"
              :alt="solutions[0].alt"
              loading="lazy"
            />
          </div>

          <!-- Right Column -->
          <div class="w-full lg:w-[55%] lg:mt-[-25%]">
            <!-- Images Container -->
            <div class="flex flex-row lg:flex-col">
              <!-- First Image (Mobile Only) -->
              <img
                class="w-1/2 lg:hidden"
                :src="solutions[0].image"
                :alt="solutions[0].alt"
                loading="lazy"
              />
              <!-- Second Image -->
              <img
                class="w-1/2 lg:w-full"
                :src="solutions[1].image"
                :alt="solutions[1].alt"
                loading="lazy"
              />
            </div>
            <p class="p-4 text-sm text-justify lg:p-8 lg:text-base">
              {{ solutions[0].description }}
            </p>
            <p class="px-4 pb-4 text-sm text-justify lg:px-8 lg:pb-8 lg:text-base">
              {{ solutions[1].description }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Third Section: Conclusion -->
    <div class="flex flex-col w-full mt-8 lg:mt-[5%]">
      <h1
        class="w-full lg:w-[45%] text-4xl lg:text-6xl font-bold text-center lg:text-right text-[#f25f38] mb-6"
      >
        Conclusion
      </h1>
      <div
        class="w-full lg:w-[50%] h-full p-4 lg:p-8 text-white bg-[#f25f38] rounded-tr-3xl rounded-br-3xl shadow-xl flex flex-col"
      >
        <div
          v-for="(content, index) in conclusions"
          :key="index"
          class="flex flex-col w-full h-full text-justify lg:flex-row justify-normal"
        >
          <div class="w-full lg:w-2/3">
            <p class="mb-4 text-sm lg:text-base">{{ content.paragraph1 }}</p>
            <p class="mb-4 text-sm lg:text-base">{{ content.paragraph2 }}</p>
          </div>
          <div class="w-full lg:w-1/3">
            <img class="w-full" :src="content.image" :alt="content.alt" loading="lazy" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const procedures = [
  {
    description:
      'The requirement for multiple protective layers in operating creates a procedural labyrinth that not only extends preparation time but also elevates the risk of inadvertently introducing contaminants into sterile zones.',
    image: '/images/gowning.png'
  },
  {
    description:
      'Entry into clean areas is gated by stringent self-cleaning protocols that require meticulous personal disinfection, a process prone to variability and one that significantly contributes to operational delays.',
    image: '/images/selfcleaning.png'
  },
  {
    description:
      'Operators must maintain a burdensome level of alertness to avoid breaches in the sterile environment. This continuous pressure to perform flawlessly can heighten the likelihood of human error, counterintuitively increasing risk.',
    image: '/images/door.png'
  },
  {
    description:
      'The operational standard demands that any area exposed to human interaction must undergo immediate and thorough disinfection, a process that repetitively strains resources and interrupts the flow of operations.',
    image: '/images/cleaning.png'
  },
  {
    description:
      'To mitigate contamination risks, the range of permissible movements within cleanrooms is severely restricted, complicating task execution and limiting operator efficiency, often leading to prolonged operation times and physical discomfort.',
    image: '/images/isolator.png'
  }
  // {
  // 	description: 'This is just the tip of the iceberg of how well-intended processes in aseptic manufacturing have become tangled in inefficiency.',
  // 	image: '/images/'
  // }
]

const solutions = [
  {
    description:
      'With AlterEcho, resource-consuming procedures are history. The operator simply steps into the control room, activates our system, and instantly takes control of the production line without being physically present in the cleanroom. This streamlined approach allows for immediate intervention, keeping the production process smooth and uninterrupted.',
    image: '/images/alterecho_robot.png', // Leave the image source empty as per previous instruction
    alt: 'Illustration of remote embodiment system'
  },
  {
    description:
      'Our technology not only speeds up interventions but also ensures unparalleled sterility. By keeping human operators outside of the sterile zones, AlterEcho drastically reduces contamination risk, making aseptic production safer and more efficient. This advanced system allows for seamless integration into existing workflows, ensuring high productivity and maintaining the highest standards of sterility with minimal disruption.',
    image: '/images/alterecho_operator.png', // Leave the image source empty
    alt: 'Illustration of advanced system integration'
  }
]

const conclusions = [
  {
    paragraph1:
      'Traditional SOPs in aseptic manufacturing require intricate and time-consuming processes such as gowning, environmental disinfection, and constant vigilance to prevent contamination. These human-dependent factors not only slow down production but also introduce potential points of failure, making it challenging to maintain the necessary sterile conditions consistently.',
    paragraph2:
      'AlterEcho offers a transformative solution by leveraging emerging technologies. This innovative approach eliminates the need for physical human presence in sterile zones, drastically reducing contamination risks and streamlining operations. By enabling operators to intervene quickly and precisely from a distance, AlterEcho not only accelerates production processes but also enhances overall safety and efficiency, setting a new standard in aseptic manufacturing.',
    image: '/images/conclusion.png',
    alt: 'Illustration of transformative solution'
  }
]
</script>

<style scoped></style>
