<template>
  <div class="flex flex-col items-center w-full p-4 bg-white md:p-8">
    <!-- Header section -->
    <div class="flex flex-col items-center justify-center w-full mb-8 text-center md:mb-12">
      <h1 class="text-2xl font-normal md:text-3xl">Challenges in</h1>
      <h1 class="mt-2 text-3xl font-bold md:text-5xl">ASEPTIC MANUFACTURING</h1>
    </div>

    <!-- Cards grid - now starts with 2 columns on mobile -->
    <div class="grid w-full grid-cols-2 gap-3 md:grid-cols-3 xl:grid-cols-6 md:gap-6">
      <div
        v-for="(challenge, index) in challenges"
        :key="index"
        class="flex flex-col p-3 bg-white rounded-lg shadow-2xl md:p-6"
      >
        <div class="flex items-center justify-center flex-1 py-2 md:py-4">
          <h1 class="text-sm font-bold text-center md:text-xl">{{ challenge.title }}</h1>
        </div>
        <div class="flex items-center justify-center flex-1 py-2 md:py-4">
          <img
            :src="challenge.image"
            :alt="challenge.title"
            class="object-contain w-[75%] max-w-[120px]"
          />
        </div>
        <div class="flex items-center justify-center flex-1 py-2 md:py-4">
          <p class="text-xs text-center md:text-base">{{ challenge.description }}</p>
        </div>
      </div>
    </div>

    <!-- Bottom spacing -->
    <div class="w-full h-8 md:h-16"></div>
  </div>
</template>

<script setup lang="ts">
const challenges = [
  {
    title: 'Low Equipment Efficiency and Unrealized Revenue',
    description:
      'Most filling lines operate below 60% OEE, leading to suboptimal output and billions in unrealized revenue annually.',
    image: '/icons/loss_icon.png'
  },
  {
    title: 'Contamination Risk and Microbial Control',
    description:
      'Even minor breaches can lead to extensive batch contamination, endangering patient safety and product efficacy.',
    image: '/icons/contamination_icon.png'
  },
  {
    title: 'Process Validation and Regulatory Compliance',
    description:
      'Meeting diverse global standards demands meticulous process documentation and consistent operational adherence.',
    image: '/icons/regulatory_icon.png'
  },
  {
    title: 'Operator Reliability and Human Error',
    description:
      'Operational mistakes, despite stringent protocols, continue to introduce variability and potential failures.',
    image: '/icons/error_icon.png'
  },
  {
    title: 'Environmental Monitor and Data Integrity',
    description:
      'Continuous monitoring faces challenges in ensuring accurate, tamper-proof records essential for quality assurance.',
    image: '/icons/disinfection_icon.png'
  },
  {
    title: 'Line Interventions and Equipment Maintenance',
    description:
      'Frequent mechanical disruptions require swift, sterile interventions to prevent downtime and contamination.',
    image: '/icons/intervention_icon.png'
  }
]
</script>

<style scoped></style>
