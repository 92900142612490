<template>
  <div class="pt-24 bg-black">
    <p class="text-center text-white">
      We do not fear the future, for we envision how to build it.
    </p>
  </div>
</template>

<script setup lang="ts"></script>
<style scoped></style>
