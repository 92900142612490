<template>
  <div
    class="flex items-center justify-center bg-black text-white px-4 md:px-[10%] md:w-screen md:h-screen"
  >
    <div class="flex flex-col w-full h-full justify-evenly">
      <!-- Top Spacer: 15% of parent's height on desktop -->
      <div class="md:h-[15%]"></div>

      <!-- Content Section -->
      <div class="flex flex-col items-center justify-around w-full md:flex-row">
        <div
          v-for="item in items"
          :key="item.id"
          class="flex flex-col items-center justify-between w-full px-4 mb-8 text-center md:w-1/4 md:mb-0"
        >
          <img class="w-3/5 h-auto mb-4" :src="item.imageUrl" :alt="item.alt" loading="lazy" />
          <h2 class="text-2xl font-bold md:text-3xl">{{ item.title }}</h2>
          <p class="w-4/5 text-lg font-bold">{{ item.subtitle }}</p>
          <p class="w-4/5 text-base text-justify">{{ item.description }}</p>
        </div>
      </div>

      <!-- Bottom Spacer: 10% of parent's height on desktop -->
      <div class="md:h-[10%]"></div>

      <!-- Statistics Section -->
      <div class="flex flex-col justify-around w-full md:flex-row">
        <div
          v-for="stat in stats"
          :key="stat.id"
          class="flex flex-col items-center justify-center w-full mb-6 text-center md:w-1/4 md:mb-0"
        >
          <div class="text-5xl font-bold md:text-6xl">{{ stat.value }}</div>
          <div class="text-xl md:text-2xl">{{ stat.label }}</div>
        </div>
      </div>

      <!-- Additional Bottom Spacer if needed -->
      <div class="md:h-[10%]"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

interface Item {
  id: number
  imageUrl: string
  alt: string
  title: string
  subtitle: string
  description: string
}

interface Stat {
  id: number
  value: string
  label: string
}

const items = ref<Item[]>([
  {
    id: 1,
    imageUrl: '/images/LessIsMore.png',
    alt: 'Simplified process illustration',
    title: 'LESS is MORE',
    subtitle: 'Simplify processes, reduce human intervention, and minimize contamination risks.',
    description:
      'AlterEcho streamlines operations by eliminating unnecessary steps, resulting in cleaner and more efficient production.'
  },
  {
    id: 2,
    imageUrl: '/images/WasteNoTime.png',
    alt: 'Clock on fire illustration',
    title: 'WASTE no TIME',
    subtitle: 'Immediate responses, quicker interventions, and seamless integration.',
    description:
      'AlterEcho ensures that no time is wasted, significantly boosting overall equipment efficiency and productivity.'
  },
  {
    id: 3,
    imageUrl: '/images/DoItRight.png',
    alt: 'Precision hand illustration',
    title: 'DO it RIGHT',
    subtitle: 'Achieve precision and reliability with every operation.',
    description:
      "AlterEcho's advanced technology ensures tasks are executed flawlessly, reducing waste and enhancing overall production quality."
  }
])

const stats = ref<Stat[]>([
  { id: 1, value: '-95%', label: 'Contamination Chances' },
  { id: 2, value: '+30%', label: 'Overall Equipment Efficiency' },
  { id: 3, value: '+20%', label: 'Recovered Revenue' },
  { id: 4, value: '-45%', label: 'Wasted Plastic Disposables' }
])
</script>

<style scoped></style>
