<template>
  <div class="flex items-center justify-center w-full h-full mt-40 mb-20">
    <div class="flex flex-col items-center justify-center w-3/4">
      <!-- Title section -->
      <h1 class="my-2 text-5xl text-left">
        The <b>Renaissance</b> of Aseptic Pharmaceutical Manufacturing
      </h1>
      <!-- Subtitle section -->
      <p class="mb-10 text-base text-justify lg:columns-2 lg:gap-x-8">
        In 150 CE, <b>Ptolemæus' geocentric model</b> required increasingly complex orbital
        mechanics to maintain Earth at the universe's center. In 1543,
        <b>Copernicus'</b> heliocentric model simplified these into predictable trajectories by
        placing the Sun at the center. Similarly, since the 1980s, <b>aseptic manufacturing</b> has
        burdened operators with rigid procedures for sterility. <b>AlterEcho</b> parallels this
        revolutionary shift: by centering systems around human operators and enhancing them with
        <b>modern technology</b>, we create more efficient processes. This transformation naturally
        yields higher pharmaceutical revenues, better operator conditions, and superior regulatory
        compliance.
      </p>
      <!-- Video section with titles -->
      <div class="flex flex-col justify-between w-full lg:flex-row">
        <!-- First video and title -->
        <div class="flex flex-col items-center w-full lg:w-[40%]">
          <video
            class="object-cover w-full h-auto"
            src="/videos/PtolemaicIsolator.mp4"
            autoplay
            muted
            loop
            disablePictureInPicture
          >
            Your browser does not support the video tag.
          </video>
          <h2 class="mt-4 text-3xl text-center">
            <i>Traditional Isolator System</i><br />
            1980s
          </h2>
        </div>
        <!-- Second video and title -->
        <div class="flex flex-col items-center w-full lg:w-[40%]">
          <video
            class="object-cover w-full h-auto"
            src="/videos/CopernicanIsolator.mp4"
            autoplay
            muted
            loop
            disablePictureInPicture
          >
            Your browser does not support the video tag.
          </video>
          <h2 class="mt-4 text-3xl text-center"><b>AlterEcho</b><br />2025</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
